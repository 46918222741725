<template>
  <div>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
          <div class="card card-custom">
            <!--begin::Chat-->

            <BarChart :chart-data="chartData" />
            <div class="d-flex flex-row">
              <!--begin::Aside-->
              <!--end::Aside-->

              <!--begin::Content-->
              <GPTContent v-show="false" />
              <!--end::Content-->
              <GPTContentDemo @onData="assignChartData" />
            </div>
          </div>
          <!--end::Chat-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Entry-->
    </div>
  </div>
</template>
<script>
import GPTContent from '@/view/components/chat-gpt/GPTContent.vue';
import GPTContentDemo from '@/view/components/chat-gpt/GPTContentDemo.vue';
import BarChart from '@/view/components/chat-gpt/BarChart.vue';

export default {
  components: { BarChart, GPTContent, GPTContentDemo },
  data() {
    return {
      chartData: {},
    };
  },
  methods: {
    assignChartData(data) {
      this.chartData = data;
    },
  },
};
</script>

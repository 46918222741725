<template>
  <div class="container">
    <column-chart
      :data="chartData.data"
      style="width: 90%"
      class="p-4"
      :ytitle="chartData.yTitle"
      :xtitle="chartData.xTitle"
      :height="'400px'"
      loading="Đang tải..."
      :download="true"
      thousands=","
    >
    </column-chart>
  </div>
</template>

<script>

export default {
  props: ['chartData', 'xTitle', 'yTitle'],
};
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>

<template>
  <div class="flex-row-fluid ml-lg-8" id="kt_chat_content">
    <!--begin::Footer-->
    <div class="card-footer align-items-center">
      <MessageIn :bot-name="botName" :content="content" />
      <!--begin::Compose-->
      <b-form-input
        class="form-control border-1 pl-2"
        rows="2"
        :placeholder="`Nhắn tin với ${botName}`"
        v-model="clientMessage"
        v-on:keydown.enter="sendMessage('chat-content')"
      ></b-form-input>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <div class="mr-3">
          <!--          <a href="#" class="btn btn-clean btn-icon btn-md mr-1"-->
          <!--          ><i class="flaticon2-photograph icon-lg"></i-->
          <!--          ></a>-->
          <!--          <a href="#" class="btn btn-clean btn-icon btn-md"-->
          <!--          ><i class="flaticon2-photo-camera icon-lg"></i-->
          <!--          ></a>-->
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
            @click.enter="sendMessage('chat-content')"
            :disabled="!!isBusy"
          >
            Gửi
          </button>
        </div>
      </div>
      <div class="text-center mt-4">
        <p style="color: #a9a9ac">
          {{ botName }} có thể mắc sai lầm. Kết quả chỉ mang tính chất tham
          khảo. Hãy kiểm tra lại các thông tin quan trọng.
        </p>
      </div>
      <!--begin::Compose-->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Card-->
</template>
<script>
import { MessageProperties } from '@/view/components/chat-gpt/utils/MessageProperties';
import { v4 } from 'uuid';
import { makeToastFaile } from '@/utils/common';
import MessageIn from '@/view/components/chat-gpt/components/MessageIn.vue';

export default {
  components: { MessageIn },
  data() {
    return {
      botName: 'ChatBI',
      isBusy: false,
      clientMessage: '',
      tmpClientMessage: '',
      messages: [],
      content: '',
    };
  },
  methods: {
    async sendMessage() {
      this.isBusy = true;
      this.tmpClientMessage = this.clientMessage.trim() || '';
      this.clientMessage = '';

      const humanMessage = Object.assign(
        {},
        new MessageProperties({
          id: v4(),
          content: this.tmpClientMessage.trim() || '',
          isBot: false,
        }),
      );
      this.messages.push(humanMessage);
      this.$nextTick(() => {
        this.scrollToLastMessage();
      });

      try {
        const response = await fetch(
          'http://localhost:3018/api/gpt/ask-database-demo',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              question: this.tmpClientMessage.trim() || '',
            }),
          },
        );

        if (!response.body) {
          this.handleError();
        }

        this.resetData();
        const jsonResponse = await response.json();
        this.content = jsonResponse.result;
        this.$emit('onData', JSON.parse(jsonResponse.jsonChartJs));
        this.isBusy = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    resetData() {
      this.clientMessage = '';
      this.tmpClientMessage = '';
    },
    scrollToLastMessage() {
      this.$refs.bottomEl?.scrollIntoView({ behavior: 'smooth' });
    },
    processChunk(response, aiMessage) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processChunk = ({ done, value }) => {
        if (done) {
          this.isBusy = false;
          this.$nextTick(() => {
            this.scrollToLastMessage();
          });
          return;
        }

        const chunks = decoder.decode(value, { stream: true });
        aiMessage.content += chunks;

        this.$forceUpdate();

        return reader.read().then(processChunk);
      };
      return { reader, processChunk };
    },
    createAiMessage() {
      const aiMessage = Object.assign(
        {},
        new MessageProperties({
          id: v4(),
          content: '',
          isBot: true,
        }),
      );
      this.messages.push(aiMessage);
      return aiMessage;
    },
    handleError(error) {
      this.isBusy = false;
      this.$nprogress.done();
      if (error.response) {
        makeToastFaile(
          error.response.data ? error.response.data.message : 'Lỗi',
        );
      } else {
        makeToastFaile(error.message || 'Lỗi');
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
